import React from 'react';
//import { Button } from './Button';
import './Footer.css'
import { Link } from 'react-router-dom';
import {useTranslation} from "react-i18next";


function Footer() {

    const {t} = useTranslation();

    // function handleClick(lang){
    //     i18n.changeLanguage(lang)
    // }

    return (
        <div className="footer-container">
            {/* <section className="footer-subscription">
                <p className="footer-subscription-heading">
                    Join BLABLA
                </p>
                <p className="footer-subscription-text">
                    adklgnklan
                </p>
                <div className="input-areas">
                    <form>
                        <input type="email" 
                        name="email" 
                        placeholder="Your Email"
                        className='footer-input'
                        />
                        <Button buttonStyle='btn--outline'>Subscribe</Button>
                    </form>
                </div>
                </section> */}
                <div className="footer-links">
                    <div className="footer-link-wrapper">
                        <div className="footer-link-items">
                            <h2> {t('Footer.1')}</h2>
                            <Link to='/ueberuns'>{t('Footer.2')}</Link>
                            {/*<Link to='/unserteam'>{t('Footer.3')}</Link>*/}
                            <Link to='/impressum'>{t('Footer.4')}</Link>
                        </div>
                        <div className="footer-link-items">
                            <h2>{t('Footer.5')}</h2>
                            <Link to='/kontakt'>{t('Footer.6')}</Link>
                            <Link to='/datenschutz'>{t('Footer.7')}</Link>
                        </div>
                    </div>
                    <div className="footer-link-wrapper">

                        <div className="footer-link-items">
                            <h2>{t('Footer.8')}</h2>
                            <a href='https://www.instagram.com/internationaljobplacement/'>{t('Footer.9')}</a>
                            <a href='https://www.facebook.com/pages/category/Employment-Agency/IJP-International-Job-Placement-379330239461402/'>Facebook</a>
                        </div>
                    </div>
                </div>
            <section className="social-media">
                <div className="social-media-wrap">
                    
                    {/* <div className="social-icons">
                        <Link className="social-icons-link facebook"
                            to="/"
                            target="_blank"
                            aria-label="Facebook" 
                            >
                            <i className="fab fa-facebook-f"/>
                        </Link>
                        <Link className="social-icons-link instagram"
                            to="/"
                            target="_blank"
                            aria-label="Instagram" 
                            >
                            <i className="fab fa-instagram"/>
                        </Link>  
                    </div> */}
                </div>
                <small className="website-rights">&copy; {t('Footer.10')}</small>
            </section>
        </div>
    );
}

export default Footer
