import React, {useEffect} from 'react';
import '../../App.css';
import './Bewerber.css';
import Footer from '../Footer';
import BewerberCard from '../BewerberCard';
import {useTranslation} from "react-i18next";


import Plx from "react-plx";


const exampleParallaxData = [
    {
        start: 0,
        end: 800,
        properties: [
            {
                startValue: 0,
                endValue: -300,
                property: "translateY"
            }
        ]
    }]


function Bewerber() {


    const {t} = useTranslation();


    useEffect(() => {
        document.title = 'Bewerber';
    });


    return (

        <>
        
            <div className="bewerber-container">
            </div>
            <div className='bewerber-btns'></div>

            <Plx parallaxData={exampleParallaxData}>
                <BewerberCard/>


                <section className="section1">
                    <div style={{position: 'absolute', marginTop: "-450px"}} id="BewerberFerienbeschäfitung"></div>
                    
                        

                        <h1 className="white-header">{t('BewerberPage.1')}</h1>
                        <div>
                            <div className="white-short-header"> {t('BewerberPage.2')}
                            </div>
                            <div className="white-text">
                                <div className="float-child">
                                    <h5 className="white-header2"> {t('BewerberPage.3')} </h5>
                                    <li>
                                        {t('BewerberPage.4')}
                                    </li>
                                    <li>  {t('BewerberPage.5')}</li>
                                    <li>  {t('BewerberPage.6')}</li>
                                    <li>  {t('BewerberPage.7')}</li>
                                    <li>  {t('BewerberPage.8')}</li>
                                    <li>  {t('BewerberPage.9')}</li>
                                    <li>  {t('BewerberPage.10')}</li>
                                    <li>  {t('BewerberPage.11')}</li>

                                    <div className="bottom-text">  {t('BewerberPage.12')}
                                        <p> <a href="https://docs.google.com/forms/d/1O87-xbJhBsx2ggvKiSg37O5XIQd-CiZ9DOuIJhsBkBI/viewform?fbclid=PAAaY9TX--1ldxlMyBkDWFNW9W7nL09WuLsMnYN_pLbvc_xjtZ_b0XIRZ0llo_aem_ATwEB8bGNZ0Ls66nqEcvwgz5w2aA44YKQdV5SlOemyLZzP9CRqiQLPKp3RMxtLdtaqo&edit_requested=true" target="_blank" rel="noreferrer">hier klicken! </a> </p>
                                    </div>

                                </div>
                                

                                <div className="float-child">
                                    <h5 className="white-header2"> {t('BewerberPage.13')} </h5>
                                    <li>
                                         {t('BewerberPage.14')}
                                    </li>
                                    <li>  {t('BewerberPage.15')}</li>
                                    <li>  {t('BewerberPage.16')}</li>
                                    <li>  {t('BewerberPage.17')}</li>
                                </div>
                            </div>
                        </div>

                </section>


                <section className="section2">
                    <div style={{position: 'absolute', marginTop: "-450px"}} id="BewerberPraktikum"></div>
                    <h1 className="black-header"> {t('BewerberPage.18')}</h1>
                    <div>
                        <div className="black-short-header">  {t('BewerberPage.19')}
                        </div>
                        <div className="black-text">
                            <div className="float-child">
                            <h5 className="black-header2">  {t('BewerberPage.20')} </h5>
                            <li>
                                {t('BewerberPage.21')}
                            </li>
                            <li>{t('BewerberPage.22')}
                            </li>
                            <li> {t('BewerberPage.23')}</li>
                            <li> {t('BewerberPage.24')}</li>


                                <div className="bottom-text"> {t('BewerberPage.25')}
                                    <p> <a href="https://docs.google.com/forms/d/1O87-xbJhBsx2ggvKiSg37O5XIQd-CiZ9DOuIJhsBkBI/viewform?fbclid=PAAaY9TX--1ldxlMyBkDWFNW9W7nL09WuLsMnYN_pLbvc_xjtZ_b0XIRZ0llo_aem_ATwEB8bGNZ0Ls66nqEcvwgz5w2aA44YKQdV5SlOemyLZzP9CRqiQLPKp3RMxtLdtaqo&edit_requested=true" target="_blank" rel="noreferrer">hier klicken! </a> </p>
                                </div>

                        </div>


                            <div className="float-child">
                                <h5 className="black-header2"> {t('BewerberPage.26')} </h5>
                                <li>{t('BewerberPage.27')}</li>
                                <li> {t('BewerberPage.28')}</li>
                                <li>
                                   {t('BewerberPage.29')}
                                </li>
                                <li> {t('BewerberPage.30')} </li>
                                <li>{t('BewerberPage.31')}</li>
                            </div>


                        </div>


                    </div>

                </section>


                <section className="section3">
                    <div style={{position: 'absolute', marginTop: "-450px"}} id="BewerberFSJ"></div>
                        <h1 className="white-header">{t('BewerberPage.32')}</h1>
                        <div>
                            <div className="white-short-header">
                               {t('BewerberPage.33')}

                            </div>
                            <div className="white-text">
                                <div className="float-child">
                                <h5> {t('BewerberPage.34')} </h5>
                                <li>
                                   {t('BewerberPage.35')}
                                </li>
                                <li>{t('BewerberPage.36')}</li>
                                <li> {t('BewerberPage.37')}</li>
                                <li> {t('BewerberPage.38')}</li>
                                <li> {t('BewerberPage.39')}</li>

                                    <div className="bottom-text"> {t('BewerberPage.40')}
                                        <p> <a href="https://docs.google.com/forms/d/1O87-xbJhBsx2ggvKiSg37O5XIQd-CiZ9DOuIJhsBkBI/viewform?fbclid=PAAaY9TX--1ldxlMyBkDWFNW9W7nL09WuLsMnYN_pLbvc_xjtZ_b0XIRZ0llo_aem_ATwEB8bGNZ0Ls66nqEcvwgz5w2aA44YKQdV5SlOemyLZzP9CRqiQLPKp3RMxtLdtaqo&edit_requested=true" target="_blank" rel="noreferrer">hier klicken! </a> </p>
                                    </div>

                            </div>
                                <div className="float-child">
                                    <h5> {t('BewerberPage.41')} </h5>
                                    <li> {t('BewerberPage.42')}</li>
                                    <li>{t('BewerberPage.43')}</li>
                                    <li>{t('BewerberPage.44')}</li>
                                    <li> {t('BewerberPage.45')}</li>
                                    <li> {t('BewerberPage.46')}</li>
                                </div>
                            </div>

                        </div>
                   

                </section>


                <section className="section4">
                    <div style={{position: 'absolute', marginTop: "-450px"}} id="BewerberVollzeit"></div>
                    <h1 className="black-header">{t('BewerberPage.47')}</h1>
                        <div className="black-short-header"> {t('BewerberPage.48')}
                        </div>
                        <div className="black-text">
                            <div className="float-child">
                            <h5> {t('BewerberPage.49')} </h5>
                            <li>
                                {t('BewerberPage.50')}
                            </li>
                            <li> {t('BewerberPage.51')}
                            </li>
                            <li>{t('BewerberPage.52')}</li>

                            <div className="bottom-text"> {t('BewerberPage.53')}
                                <p> <a href="https://docs.google.com/forms/d/1O87-xbJhBsx2ggvKiSg37O5XIQd-CiZ9DOuIJhsBkBI/viewform?fbclid=PAAaY9TX--1ldxlMyBkDWFNW9W7nL09WuLsMnYN_pLbvc_xjtZ_b0XIRZ0llo_aem_ATwEB8bGNZ0Ls66nqEcvwgz5w2aA44YKQdV5SlOemyLZzP9CRqiQLPKp3RMxtLdtaqo&edit_requested=true" target="_blank" rel="noreferrer">hier klicken! </a> </p>
                            </div>

                            </div>
                            <div className="float-child">
                                <h5> {t('BewerberPage.54')} </h5>
                                <li> {t('BewerberPage.55')}</li>
                                <li>{t('BewerberPage.56')}</li>
                                <li>{t('BewerberPage.57')}</li>
                                <li> {t('BewerberPage.58')}</li>
                                <li> {t('BewerberPage.59')}</li>
                            </div>
                    </div>
            </section>

            </Plx>


 <div className="footer-bewerber" >
     <Footer/>
 </div>


        </>

    );


}


export default Bewerber;


