import React from 'react'
//import { Parallax, Background } from "react-parallax";
import StudentImg from '../../assets/Bewerber_Student.jpg'
import './Ferienbeschaeftigung.css'
import Plx from "react-plx";



const exampleParallaxData = [
    {
        start: 0,
        end: 500,
        properties: [
            {
                startValue: 0,
                endValue: -200,
                property: "translateY"
            }
        ]
    }
];



const Ferienbeschaeftigung = () => {


    return (

<div>


    <Plx parallaxData={exampleParallaxData}>
        <img className="ferienbeschaeftigung-container" src={StudentImg} alt='Deko'/>
    </Plx>


            <h1>TEST</h1>
            <div style={{ height: 3000 }} />





        </div>
    );
}

export default Ferienbeschaeftigung;