import emailjs from "emailjs-com";
import React from 'react';
import {useTranslation} from "react-i18next";

function ContactForm() {

    const {t} = useTranslation();


    function sendEmail(e) {
        e.preventDefault();

        // emailjs.sendForm('service_lxkbira', 'contactform', e.target, 'user_H3ueg7RvEsRMNh44v8hqf')
        emailjs.sendForm('service_s47eb91', 'template_skk05ym', e.target, 'mO1YQhwihahYWRoyw')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
        e.target.reset()
    }

    return (
        <div>
            <h1 style={{paddingTop: "70px"}}>{t('Contactform.1')}</h1>
            <div className="container">
                <form onSubmit={sendEmail}>
                    <div className="row pt-5 mx-auto">
                        <div className="col-8 form-group mx-auto">
                            <input type="text" className="form-control" placeholder={t('Contactform.2')} name="name"/>
                        </div>
                        <div className="col-8 form-group pt-2 mx-auto">
                            <input type="email" className="form-control" placeholder={t('Contactform.3')} name="email"/>
                        </div>
                        <div className="col-8 form-group pt-2 mx-auto">
                            <input type="text" className="form-control" placeholder={t('Contactform.4')} name="subject"/>
                        </div>
                        <div className="col-8 form-group pt-2 mx-auto">
                            <textarea className="form-control" id="" cols="30" rows="8" placeholder={t('Contactform.5')}
                                      name="message"></textarea>
                        </div>

                        <style type="text/css">
                            {`
                                .btn-flat {
                                    background-color: #E85900;
                                    color: white;
                                            }
                                .btn-xxl {
                                     padding: 1rem 1.5rem;
                                     font-size: 1.5rem;
                                        }
                            `}
                        </style>

                        <div className="col-8 pt-3 mx-auto">
                            <input type="submit" className="btn btn-flat" value="Nachricht Senden"></input>
                        </div>
                    </div>
                </form>
            </div>
        </div>

    )
}


export default ContactForm;