import React from 'react';
import '../../App.css';
import './Ausbildung.css';
import Footer from '../Footer';
import AusbildungCard from '../AusbildungCard';


function Ausbildung() {
    return( 
        
<div>
    <div>
      <div className='Ausbildung-container'>
    </div>  
            <div className='Ausbildung-btns' ></div>
        </div>
        <AusbildungCard/>


        <Footer />

        </div>
    );


}


export default Ausbildung;
