import React from 'react';
import PartnerCount from './PartnerCount';
import VermittlungCount from './VermittlungCount';
import './Number.css'
import ElseCount from './ElseCount';

function Number () {
    return(
<div className='numbers'>
<div className='box'>
 <div className='container_numbers'>
    <span>
    <PartnerCount   />
    </span>
    <span >
    <VermittlungCount />
    </span>
    <span >
    <ElseCount />
    </span>

    </div>
 </div>

 </div>
    )
}

export default Number


// //OHNE ON SCROLL

// import React, { useState } from 'react'
// import './Number.css'
// import AnimatedNumber from 'react-animated-number'

// function Number () {
//   const [counter, setCounter] = useState(0)
//   const [counterT, setCounterT] = useState(0)
//   const [counterTT, setCounterTT] = useState(0)

//   return (
//     <div className='numbers'>
//        <div  className='numbers__items'>
//       <AnimatedNumber
//         value={counter}
//         style={
//           {
//             fontSize: 100
//           }
//         }
//         formatValue={n => n.toFixed(0)}
//         frameStyle={percentage => percentage > 0 && percentage < 1 ? { opacity: 0.5 } : {}}
//       />
     
    
//       <p style={{fontSize: 20, marginTop: -20, marginLeft: -50}} >Personen erfolgreich </p>
//       <p style={{fontSize: 20, marginTop: 0, marginLeft: -178}} >vermittelt</p>

//       <div className='area'>
//         <button onClick={() => setCounter(counter - 13)} className='button' >Decrease</button>
//         {/* <button onClick={() => setCounter(counter + 13)} className='button green'>Increase</button> */}
//       </div>

//       <AnimatedNumber    
//        value={counterT}
//        style={
//          {
//            fontSize: 100
//          }
//        }
//        formatValue={n => n.toFixed(0)}
//        frameStyle={percentage => percentage > 20 && percentage < 80 ? { opacity: 0.5 } : {}}
//      />
//      <p style={{fontSize: 20, marginTop: -20, marginLeft: -50}} >Zufriedene Partner </p>

//       <div className='area'>
//         <button onClick={() => setCounterT(counterT - 13)} className='button' >Decrease</button>
//         {/* <button onClick={() => setCounterT(counterT + 13)} className='button green'>Increase</button> */}
//       </div>

//       <AnimatedNumber    
//        value={counterTT}
//        style={
//          {
//            fontSize: 100
//          }
//        }
//        formatValue={n => n.toFixed(0)}
//        frameStyle={percentage => percentage > 20 && percentage < 80 ? { opacity: 0.5 } : {}}
//      />
//      <p style={{fontSize: 20, marginTop: -20, marginLeft: -50}} >Kinder verkauft </p>

//       <div className='area'>
//         <button onClick={() => setCounterTT(counterTT - 13)} className='button' >Decrease</button>
//         {/* <button onClick={() => setCounterT(counterT + 13)} className='button green'>Increase</button> */}
//       </div>

// </div>
//     </div>
//   )
// }

// export default Number