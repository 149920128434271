import React from 'react';
import './Button.css';
import { Link } from 'react-router-dom';

// const STYLES = ['btn--primary', 'btn--outline', 'btn--test'];

// const SIZES = ['btn--medium', 'btn--large'];

export const Button = ({
  children,
  // buttonStyle,
  // buttonSize,
  path
}) => {
  // const checkButtonStyle = STYLES.includes(buttonStyle)
  //   ? buttonStyle
  //   : STYLES[0];

  // const checkButtonSize = SIZES.includes(buttonSize) ? buttonSize : SIZES[0];

  return (
    <Link to={path} className='btn-mobile'>
      <button type="button" class="btn btn-dark">
        {children}
      </button>
    </Link>
  );
};
