import React from 'react'
import ContactForm from "../ContactForm";
import Footer from "../Footer"
import './Kontakt.css';

const Kontakt = () => {


    return(
        <div>
            <div className="padding">
            <ContactForm/>
            </div>
            <Footer/>
        </div>


    )
}


export default Kontakt;