import React from 'react'
import './UeberUns.css'
import Footer from "../Footer";
import CardPersonal from "../CardPersonal";


const UeberUns = () => {

    return(
        <>
            <h1 className="head">
               Über Uns
            </h1>

            <div className="line">

            </div>

            <p className="font">
            Am 06.05.2019 haben wir die IJP International Job Placement UG gegründet. Inspiriert von unseren persönlichen Auslandserfahrungen, verfolgen wir mit Leidenschaft das Ziel, jungen Menschen aus verschiedenen Ländern die einzigartige Gelegenheit zu bieten, die deutsche Kultur im Kontext ihres beruflichen Alltags zu entdecken. Unsere Expertise liegt in der Vermittlung und Beratung für Programme wie FSJ, Au-pair, Ferienbeschäftigungen und Ausbildungen.
            </p>

            <div className="cards_inline">

            <CardPersonal
                src='images/Tim.jpeg'
                text="Tim Schäfer"
                text_1="Geschäftsführer"
                //path='/praktikum'
            />

               {/* <div className="cars_margin">*/}
            <CardPersonal
                src='images/Amir.jpg'
                text="Amir Bobojonov"
                text_1="Senior Recruiter"
                //path='/praktikum'
            />

                <CardPersonal
                    src='images/SaloImg2.jpg'
                    text = "S. Tursunov"
                    text_1 = "Partner Usbekistan"
                    //path='/praktikum'
                />
{/*
                </div>
*/}
            </div>


            <div  className="footer" >
            <Footer/>
            </div>



        </>
    )
}

export default UeberUns;