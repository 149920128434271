import React from 'react';
import './UnternehmenspartnerCard.css'
import './CardItem.css'

function UnternehmenspartnerCardItem(props) {
  return (
    <>
     {/* <li className='unternehmens_cards__item__link'>*/}
            <div className="unternehmenspartner_cards">
                <div className="unternehmens_cards__container">
                    <div className="unternehmens_cards__item__link">
                        <h1 className='unternehmens_cards__header__text'>{props.text1}</h1>
                        <h1 className='unternehmens_cards__second__text'>{props.text2}</h1>
                        <h5 className='unternehmens_cards__third__text'>{props.text3}</h5>
                    </div>
                </div>
            </div>

    {/*  </li>*/}
    </>
  );
}

export default UnternehmenspartnerCardItem;