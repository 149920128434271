import React, { useState} from 'react';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
import { useTranslation } from 'react-i18next';

const ElseCount = () => {

    const {t} = useTranslation();
    const [viewPortEntered, setViewPortEntered] = useState(false);
  return(
        <span >
        <p className='font-size-n' >{t('Numbers.3')}</p>
       
        <CountUp end={24} start={viewPortEntered ? null : 0}>
          {({ countUpRef }) => (
            <VisibilitySensor
              active={!viewPortEntered}
              onChange={(isVisible) => {
                if (isVisible) {
                  setViewPortEntered(true);
                }
              }}
              delayedCall
            >
              <div
              className='number-size-n'
                ref={countUpRef}
              />
            </VisibilitySensor>
          )}
        </CountUp>

      </span>
            
  );
            }
export default ElseCount;

