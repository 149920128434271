import React from 'react';
import '../../App.css';
import './FSJ.css';
import Footer from '../Footer';
import FSJCard from '../FSJCard';


function FSJ() {
    return( 
        
<div>
    <div>
      <div className='FSJ-container'>
    </div>  
            {/* <h1 className='bewerber-btns' >Bewerber</h1> */}
            <div className='FSJ-btns' ></div>
        </div>
        <FSJCard/>
        <Footer />
        </div>
    );


}


export default FSJ;
