import React from 'react'
import * as ReactBootStrap from 'react-bootstrap';
import './NavbarBootstrap.css'
import { HashLink as Link } from 'react-router-hash-link';

import { useTranslation } from 'react-i18next';

import {Link as LinkReact} from 'react-router-dom';

function NavbarBootstrap(){

  const {t, i18n} = useTranslation();

  function handleClick(lang){
    i18n.changeLanguage(lang)
  }

return(

<ReactBootStrap.Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" fixed='top'>
<div className="navbar-brand">
  <Link to="/">
  <img src='images/IJPLogoNeuSW.png' width="70" height="40" alt='LogoDeko'/>
  </Link>
</div>
{/*  <ReactBootStrap.Navbar.Brand href="/">International Job Placement</ReactBootStrap.Navbar.Brand>*/}

  <ReactBootStrap.Navbar.Toggle aria-controls="responsive-navbar-nav" />
  <ReactBootStrap.Navbar.Collapse id="responsive-navbar-nav">
    <ReactBootStrap.Nav className="mr-auto">
    <ReactBootStrap.Nav.Link href="/" navbarColor>{t('Navbar.1')}</ReactBootStrap.Nav.Link>


      <ReactBootStrap.NavDropdown title={t('Navbar.9')} id="collasible-nav-dropdown">
        <ReactBootStrap.NavDropdown.Item> 
          <LinkReact style={{ textDecoration: 'none', color:'black'}} to={"/unternehmen"}> {t('Navbar.3')} </LinkReact>
          {/* <Link style={{ textDecoration: 'none', color:'black'}} to='/unternehmen'>{t('Navbar.3')} </Link>   */}
           </ReactBootStrap.NavDropdown.Item>
        <ReactBootStrap.NavDropdown.Divider />



        <Link style={{ textDecoration: 'none' }} smooth to = '/unternehmen#Ferienbeschäftigung'>
        <ReactBootStrap.NavDropdown.Item href='/unternehmen#Ferienbeschäftigung'>{t('Navbar.11')}</ReactBootStrap.NavDropdown.Item>
        </Link>




        <Link style={{ textDecoration: 'none' }} smooth to = '/unternehmen#Praktikum'>
        <ReactBootStrap.NavDropdown.Item href="/unternehmen#Praktikum">{t('Navbar.12')}</ReactBootStrap.NavDropdown.Item>
        </Link>

        <Link style={{ textDecoration: 'none' }} smooth to = '/unternehmen#Ausbildung'>
        <ReactBootStrap.NavDropdown.Item href="/unternehmen#Ausbildung">{t('Navbar.13')}</ReactBootStrap.NavDropdown.Item>
        </Link>

        <Link style={{ textDecoration: 'none' }} smooth to = '/unternehmen#FSJ'>
        <ReactBootStrap.NavDropdown.Item href="/unternehmen#FSJ">{t('Navbar.14')}</ReactBootStrap.NavDropdown.Item>
        </Link>

      </ReactBootStrap.NavDropdown>


      <ReactBootStrap.NavDropdown title={t('Navbar.10')} id="collasible-nav-dropdown">



        <ReactBootStrap.NavDropdown.Item> <Link style={{ textDecoration: 'none', color:'black'}} to='/bewerber'> {t('Navbar.2')} </Link> </ReactBootStrap.NavDropdown.Item>
        <ReactBootStrap.NavDropdown.Divider />

        <Link style={{ textDecoration: 'none' }} smooth to = '/bewerber#BewerberFerienbeschäfitung'>
        <ReactBootStrap.NavDropdown.Item href="/bewerber#BewerberFerienbeschäftigung">{t('Navbar.4')}</ReactBootStrap.NavDropdown.Item>
        </Link>

        <Link style={{ textDecoration: 'none' }} smooth to = '/bewerber#BewerberPraktikum'>
        <ReactBootStrap.NavDropdown.Item href="/bewerber">{t('Navbar.5')}</ReactBootStrap.NavDropdown.Item>
        </Link>

        <Link style={{ textDecoration: 'none' }} smooth to = '/bewerber#BewerberFSJ'>
        <ReactBootStrap.NavDropdown.Item href="/bewerber#BewerberFSJ">{t('Navbar.6')}</ReactBootStrap.NavDropdown.Item>
        </Link>

        <Link style={{ textDecoration: 'none' }} smooth to = '/bewerber#BewerberVollzeit'>
        <ReactBootStrap.NavDropdown.Item href="/bewerber#BewerberVollzeit">{t('Navbar.7')}</ReactBootStrap.NavDropdown.Item>
        </Link>


      </ReactBootStrap.NavDropdown>


    <ReactBootStrap.Nav.Link href="https://momente-sprachschule.com/" navbarColor>{t('Navbar.15')}</ReactBootStrap.Nav.Link>

    </ReactBootStrap.Nav>


    <ReactBootStrap.Nav>


    </ReactBootStrap.Nav>

    <button onClick={() => handleClick('ger')} style={{backgroundColor: 'transparent', border:'transparent'}}>
      🇩🇪
    </button>

    <button onClick={() => handleClick('rus')} style={{backgroundColor: 'transparent', border:'transparent'}}>
      🇷🇺
    </button>

    <button onClick={() => handleClick('uzb')} style={{backgroundColor: 'transparent', border:'transparent'}}>
      🇺🇿
    </button>

    <button onClick={() => handleClick('en')} style={{backgroundColor: 'transparent', border:'transparent'}}>
      🇬🇧
    </button>

  </ReactBootStrap.Navbar.Collapse>

</ReactBootStrap.Navbar>


    )
}

export default NavbarBootstrap;