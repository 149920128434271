import React from 'react';
import '../App.css';
import { Button } from './Button';
import './HeroSection.css';

import { useTranslation } from 'react-i18next';


function HeroSection() {

    const {t} = useTranslation();

    // function handleClick(lang){
    //     i18n.changeLanguage(lang)
    // }

  return (
    <div className='hero-container'>
      {/* <video src='/videos/video-1.mp4' autoPlay loop muted /> */}
      <images src='/images/UnsereGebiete.jpg'/>
      <h1> {t('Welcome.1')}</h1>
      
      <p>{t('Welcome.2')}</p>
      <div className='hero-btns'>
        <Button
          className='btns'
          buttonStyle='btn--outline'
          buttonSize='btn--large'
          path='/bewerber'
        >
            {t('WelcomeButton.1')}
        </Button>
        <Button
          className='btns'
          buttonStyle='btn--primary'
          buttonSize='btn--large'
          path='/unternehmen'
          onClick={console.log('hey')}
        >{t('WelcomeButton.2')}
            {/* Mitarbeitersuche <i className='far fa-play-circle' /> */}
        </Button>

        
      </div>
    </div>
  );
}

export default HeroSection;

