import React from 'react';
import './BewerberCards.css';
import BewerberCardItem from './BewerberCardItem';
import { useTranslation } from 'react-i18next';

function BewerberCard() {

    const {t} = useTranslation();

    // function handleClick(lang){
    //     i18n.changeLanguage(lang)
    // }

  return (
    <>
      <div className='bewerber_cards_container'>
        <div>
          <div>
            <BewerberCardItem
              textHeader={t('InfoStudents.1')}
              textUeber={t('InfoStudents.2')}
              text={t('InfoStudents.3')}
              text2={t('InfoStudents.4')}
              text3={t('InfoStudents.5')}
            />
 </div>
        </div>
      </div>
  </>

  );
}

export default BewerberCard;