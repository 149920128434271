import React from 'react';
import './PraktikumCard.css';
import {useTranslation} from "react-i18next";


function PraktikumCard() {

    const {t} = useTranslation();

    // function handleClick(lang){
    //     i18n.changeLanguage(lang)
    // }


    return (
    <div className='praktikum_cards'>
      <div className='praktikum_cards__container'>
        <div className='praktikum_cards__wrapper'>
          <ul className='praktikum_cards__items'>

          <li className='praktikum_cards__item__link'>
          {/* <div className='praktikum_cards__item__info'> */}

        <h1 className='praktikum_cards__header__text'> {t('Internal.1')} </h1>
      
        <h1 className='praktikum_cards__second__text'>  {t('Internal.2')} </h1>

        <ol className='praktikum_cards__third__text'> 
        <p className='praktikum_cards_textHeader'>{t('Internal.3')}</p>
            <p className='praktikum_cards_text'> &#8226; {t('Internal.4')}</p>
            <p className='praktikum_cards_text'> &#8226; {t('Internal.5')}</p>
            <p className='praktikum_cards_text'> &#8226; {t('Internal.6')} </p>

            <p className='praktikum_cards_textHeader'> {t('Internal.7')}</p>
            <p className='praktikum_cards_text'> {t('Internal.8')} </p>
            <p className='praktikum_cards_text'> &#8226; {t('Internal.9')}</p>
            <p className='praktikum_cards_text'> &#8226; {t('Internal.10')} </p>
           
            <p className='praktikum_cards_textHeader'> {t('Internal.11')}</p>
            <p className='praktikum_cards_text'> &#8226; {t('Internal.12')}</p>
   

            <p className='praktikum_cards_textHeader'> {t('Internal.13')}</p>
            <p className='praktikum_cards_text'> &#8226; {t('Internal.14')}</p>
            <p className='praktikum_cards_text'> &#8226; {t('Internal.15')} <a  href='https://www.bmas.de/DE/Themen/Arbeitsrecht/Mindestlohn/mindestlohn-praktikum.html'>{t('Internal.32')}</a>.</p>


            <p className='praktikum_cards_textHeader'> {t('Internal.16')} </p>
            <p className='praktikum_cards_text'> &#8226; {t('Internal.17')} </p>
            <p className='praktikum_cards_text'> &#8226; {t('Internal.18')}  </p>
          
            <p className='praktikum_cards_textHeader'> {t('Internal.19')} </p>
            <p className='praktikum_cards_text'> &#8226; {t('Internal.20')} </p>

            <p className='praktikum_cards_textHeader'> {t('Internal.21')} </p>
            <p className='praktikum_cards_text'> &#8226; <h style={{fontWeight: "bold"}}> {t('Internal.22')} </h> </p>
            <p className='praktikum_cards_text'> &#8226;  <h style={{fontWeight: "bold"}}> {t('Internal.23')} </h> </p>

            <p className='praktikum_cards_textHeader'> {t('Internal.24')}</p>
            <p className='praktikum_cards_text'> {t('Internal.26')}</p>
            <p className='praktikum_cards_text'> &#8226; {t('Internal.27')}, </p>
            <p className='praktikum_cards_text'> &#8226;{t('Internal.28')} </p>
            <p className='praktikum_cards_text'> &#8226; {t('Internal.29')} </p>
            <p className='praktikum_cards_text'> &#8226; {t('Internal.30')} </p>
            <p className='praktikum_cards_text'> &#8226; {t('Internal.31')}</p>
            


           
      </ol>
    {/* </div> */}
        </li>


 </ul>
        </div>
      </div>
    </div>
  );
}

export default PraktikumCard;