import React from "react";
import {useTranslation} from "react-i18next";

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemButton
} from "react-accessible-accordion";

// Demo styles, see 'Styles' section below for some notes on use.
import "react-accessible-accordion/dist/fancy-example.css";
import './Accordion.css'

export default function Example() {
    const {t} = useTranslation();
    return (
        <div className="font_accordion" style={{paddingLeft: '10%', paddingRight: '10%'}}>
            <Accordion preExpanded={[]} allowZeroExpanded allowMultipleExpanded>
                {/*allowMultipleExpanded*/}
                <AccordionItem uuid={1}>
                    <AccordionItemHeading>
                        <AccordionItemButton id="Ferienbeschäftigung">
                            {t('UnternehmerPage.15')}
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel style={{backgroundColor: '#F4F4F4'}}>
                        <li className="font_accordion_first_element">  {t('UnternehmerPage.16')}
                        </li>
                        <li className="font_accordion">  {t('UnternehmerPage.17')}
                        </li>
                        <li className="font_accordion"> {t('UnternehmerPage.18')}
                        </li>
                    </AccordionItemPanel>
                </AccordionItem>
            </Accordion>

            <div style={{marginTop: "10px"}}>
                <Accordion preExpanded={[]} allowMultipleExpanded allowZeroExpanded>
                    <AccordionItem uuid={1}>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                {t('UnternehmerPage.19')}
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel style={{backgroundColor: '#F4F4F4'}}>
                            <li className="font_accordion_first_element">
                                {t('UnternehmerPage.20')}
                            </li>
                            <li className="font_accordion">
                               {t('UnternehmerPage.21')}
                            </li>
                            <li className="font_accordion">
                               {t('UnternehmerPage.22')}
                            </li>

                        </AccordionItemPanel>
                    </AccordionItem>
                </Accordion>
            </div>

            <div style={{marginTop: "10px"}}>
                <Accordion preExpanded={[]} allowMultipleExpanded allowZeroExpanded>
                    <AccordionItem uuid={1}>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                {t('UnternehmerPage.23')}
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel style={{backgroundColor: '#F4F4F4'}}>
                            <li className="font_accordion_first_element">
                                {t('UnternehmerPage.24')}</li>
                            <li className="font_accordion">
                                {t('UnternehmerPage.25')}
                            </li>
                            <li className="font_accordion">
                                {t('UnternehmerPage.26')}
                            </li>
                            <li className="font_accordion">
                                {t('UnternehmerPage.27')}
                            </li>
                            <li className="font_accordion">
                               {t('UnternehmerPage.28')}
                            </li>
                        </AccordionItemPanel>
                    </AccordionItem>
                </Accordion>
            </div>

            <div style={{marginTop: "10px"}}>
                <Accordion preExpanded={[]} allowMultipleExpanded allowZeroExpanded>
                    <AccordionItem uuid={1}>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                               {t('UnternehmerPage.29')}
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel style={{backgroundColor: '#F4F4F4'}}>
                            <li className="font_accordion_first_element">
                                {t('UnternehmerPage.30')}
                            </li>
                            <li className="font_accordion">
                                {t('UnternehmerPage.33')}
                            </li>
                            <li className="font_accordion">
                                 {t('UnternehmerPage.32')}
                            </li>
                            <li className="font_accordion">
                                 {t('UnternehmerPage.33')}
                            </li>
                            {/* <li className="font_accordion">
                                {t('UnternehmerPage.34')}
                            </li> */}
                        </AccordionItemPanel>
                    </AccordionItem>
                </Accordion>
            </div>


        </div>


    );
}
