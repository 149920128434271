import React from 'react';
import './AusbildungCard.css';


function AusbildungCard() {
  return (
    <div className='ausbildung_cards'>
      <div className='ausbildung_cards__container'>
        <div className='ausbildung_cards__wrapper'>
          <ul className='ausbildung_cards__items'>

          <li className='ausbildung_cards__item'
              // className='ausbildung_cards__item__link'
              >
          {/* <div className='ausbildung_cards__item__info'> */}
          
        <h1 className='ausbildung_cards__header__text'> Ausbildung </h1>
        <h1 className='ausbildung_cards__second__text'> In der Ausbildung werden dir berufliche Kenntnisse und Fähigkeiten vermittelt, die du zur Ausübung des Berufs als Fachkraft benötigst. Durch den Rahmenlehrplan ist die zeitliche und sachliche Gliederung deiner Ausbildung geregelt, sodass du das Ausbildungsziel in der vorgesehenen Dauer erreichen kannst. Im Gegenzug musst du dich aktiv integrieren, um dir so die erforderlichen Kenntnisse und Fähigkeiten anzueignen. Du beendest die Ausbildung mit mehreren Prüfungen (mündlich und schriftlich). In der Regel hast du nach maximal vier Jahren deine Ausbildung beendet, verdienst dein eigenes Geld und bist fit für das Berufsleben.     </h1>

        <ol className='ausbildung_cards__third__text'> 
        <p className='ausbildung_cards_textHeader'>Voraussetzungen um eine Ausbildung in Deutschland durchzuführen:</p>
            <p className='ausbildung_cards_text'> &#8226; Vollendung der gesetzlichen Schulpflicht</p>
            <p className='ausbildung_cards_text'> &#8226; Alter: 18-26 Jahre </p>
            <p className='ausbildung_cards_text'> &#8226; Zertifizierte Deutschkenntnisse  d.h. mindestens auf B1-Niveau </p>
            <p className='ausbildung_cards_text'> &#8226; Freundliches Auftreten </p>
            <p className='ausbildung_cards_text'> &#8226; Motivation </p>

            <p className='ausbildung_cards_textHeader'> Dauer der Ausbildung:</p>
            <p className='ausbildung_cards_text'> &#8226; Die Dauer der Ausbildung ist von dem Ausbildungsberuf abhängig, üblich ist jedoch eine Ausbildungszeit zwischen zwei und dreieinhalb Jahren.</p>
            <p className='ausbildung_cards_text'> &#8226; Beginn im August/September/Oktober oder Januar/Februar </p>
           
            <p className='ferienbeschaeftigung_cards_textHeader'> Was verdient man während der Ausbildung?</p>
            <p className='ausbildung_cards_text'> &#8226; In der Regel wird die Ausbildung mit 350€ – 1050€ vergütet und steigt mit jedem Ausbildungsjahr an.</p>
            <p className='ausbildung_cards_text'> &#8226; Das Ausbildungsgehalt ist abhängig von dem Ausbildungsberuf, dem ausbildenden Unternehmen und der Branche, in der du tätig ist. </p>
            <p className='ausbildung_cards_text' > Das Gehalt wird immer als Bruttogehalt ausgewiesen. Das bedeutet, dass davon noch Steuern, Versicherungen
                         und gegebenenfalls Rentenzahlungen abgezogen werden. </p>
   

            <p className='ausbildung_cards_textHeader'> Was benötigt man für die Bewerbung?</p>
            <p className='ausbildung_cards_text'> &#8226; Schulabschlusszeugnis (bzw. Hochschulabschlusszeugnis, falls vorhanden)</p>
            <p className='ausbildung_cards_text'> &#8226; Sprachnachweis</p>
            <p className='ausbildung_cards_text'> &#8226; Anschreiben </p>
            <p className='ausbildung_cards_text'> &#8226; Je nach Ausbildungsbereich können noch andere Unterlagen gefordert sein </p>

            <p className='ausbildung_cards_textHeader'> Wer begleitet dich während der Zeit im FSJ?</p>
            <p className='ausbildung_cards_text'> &#8226; Fachliche und persönliche Begleitung durch Mitarbeiter der Einrichtung</p>
            <p className='ausbildung_cards_text'> &#8226; Bist du das erste Mal in Deutschland, nimmst du an Seminaren für internationale Freiwillige teil </p>
          
            <p className='ausbildung_cards_textHeader'> Wo ist eine Ausbildung möglich?</p>
            <p className='ausbildung_cards__education_header'>  Ausbildung im Bereich Bauwesen oder Immobilien: </p>
            <p className='ausbildung_cards__education' > Baugeräteführer (m/w/d)</p>
            <p className='ausbildung_cards__education' > Bauwerksmechaniker für Abbruch- und Betontrenntechnik (m/w/d)</p>
            <p className='ausbildung_cards__education' > Straßenbauer (m/w/d)</p>
            <p className='ausbildung_cards__education' > Kanalbauer (m/w/d)</p>
            <p className='ausbildung_cards__education' > Zimmerer (m/w/d)</p>
            <p className='ausbildung_cards__education' > Anlagenmechaniker für Sanitär-, Heizungs-, und Klimatechnik (m/w/d) </p>
            <p className='ausbildung_cards__education' > Wärme-, Kälte- und Schallschutzisolierer (m/w/d) </p>
            <p className='ausbildung_cards__education' > Fliesen-, Platten- und Mosaikleger (m/w/d) </p>
            <p className='ausbildung_cards__education' > Maurer (m/w/d)</p>

            <p className='ausbildung_cards__education_header'>  Ausbildung im Bereich Büro oder in der Verwaltung:</p>
            <p className='ausbildung_cards__education' > Kaufmann für Büromanagement (m/w/d)</p>
            <p className='ausbildung_cards__education' > Kaufmann für Versicherung und Finanzen (m/w/d)</p>
            <p className='ausbildung_cards__education' > Personaldienstleistungskaufmann (m/w/d)</p>


            <p className='ausbildung_cards__education_header'>  Ausbildung im Bereich Elektronik: </p>
            <p className='ausbildung_cards__education' > Elektroniker der Fachrichtung Informations- und Telekommunikationstechnik (m/w/d)</p>
            <p className='ausbildung_cards__education' > Elektronikers für Gebäude- und Infrastruktursysteme (m/w/d)</p>
            <p className='ausbildung_cards__education' > Fluggeräteelektroniker (m/w/d)</p>
            <p className='ausbildung_cards__education' > Elektroniker für Automatisierungstechnik (m/w/d)</p>
            <p className='ausbildung_cards__education' > Industrieelektriker (m/w/d)</p>
            <p className='ausbildung_cards__education' > Elektroniker der Fachrichtung Energie- und Gebäudetechnik (m/w/d)</p>

            <p className='ausbildung_cards__education_header'>  Ausbildung in den Bereichen Erziehung, Bildung und Theologie:</p>
            <p className='ausbildung_cards__education' > Alltagsbetreuer (m/w/d)</p>
            <p className='ausbildung_cards__education' > Atem-, Sprech- und Stimmlehrer (m/w/d)</p>
            <p className='ausbildung_cards__education' > Erzieher (m/w/d)</p>


            <p className='ausbildung_cards__education_header'>  Ausbildung in den Bereichen Finanzen, Controlling und Recht:</p>            
            <p className='ausbildung_cards__education' > Bankkaufmann (m/w/d)</p>
            <p className='ausbildung_cards__education' > Kaufmann für Versicherung und Finanze (m/w/d)</p>
            <p className='ausbildung_cards__education' > Sozialversicherungsfachangestellt (m/w/d)</p>
            <p className='ausbildung_cards__education' > Rechtsanwalts-und Justizfachangestellte (m/w/d)</p>

            <p className='ausbildung_cards__education_header'>  Ausbildung in den Bereichen Freizeit und Tourismus:</p>            
            <p className='ausbildung_cards__education' > Assistent von Business Travel Managemen (m/w/d)</p>
            <p className='ausbildung_cards__education' > Kaufmann für Tourismus und Freizeit (m/w/d)</p>

            <p className='ausbildung_cards__education_header'>  Ausbildung in den Bereichen Gesundheit, Pflege und Medizin:</p>            
            <p className='ausbildung_cards__education' > Hörakustiker (m/w/d)</p>
            <p className='ausbildung_cards__education' > Zahntechnike (m/w/d)</p>
            <p className='ausbildung_cards__education' > Augenoptiker (m/w/d)</p>
            <p className='ausbildung_cards__education' > Anästhesietechnischer Assistent (m/w/d)</p>
            <p className='ausbildung_cards__education' > Assistent für Gesundheits- und Wellnesstourismus (m/w/d)</p>
            <p className='ausbildung_cards__education' > Krankenpfleger (m/w/d)</p>
            <p className='ausbildung_cards__education' > Rettungssanitäter (m/w/d)</p>
            <p className='ausbildung_cards__education' > Zahntechnike (m/w/d)</p>

            <p className='ausbildung_cards__education_header'>  Ausbildung in den Bereichen Glas, Holz, Papier und Farbe:</p>            
            <p className='ausbildung_cards__education' > Fachkraft für Holz- und Bautenschutz (m/w/d)</p>
            <p className='ausbildung_cards__education' > Lacklaborant (m/w/d)</p>
            <p className='ausbildung_cards__education' > Holztechniker (m/w/d)</p>
            <p className='ausbildung_cards__education' > Papiertechniker (m/w/d)</p>

            <p className='ausbildung_cards__education_header'>  Ausbildung in den Bereich Handel:</p>            
            <p className='ausbildung_cards__education' > Kaufmann im Einzelhandel (m/w/d)</p>
            <p className='ausbildung_cards__education' > Verkäufer (m/w/d)</p>
            <p className='ausbildung_cards__education' > Kaufmann im Groß- und Außenhandel (m/w/d)</p>
            <p className='ausbildung_cards__education' > Kaufman im Internationalen Handel (m/w/d)</p>

            <p className='ausbildung_cards__education_header'>  Ausbildung in den Bereichen Gastronomie und Hotel</p>            
            <p className='ausbildung_cards__education' > Hotelkaufmann (m/w/d)</p>
            <p className='ausbildung_cards__education' > Hotelfachmann (m/w/d)</p>
            <p className='ausbildung_cards__education' > Koch (m/w/d)</p>
            <p className='ausbildung_cards__education' > Fachkraft im Gastgewerbe (m/w/d)</p>
            <p className='ausbildung_cards__education' > Konditor (m/w/d)</p>
            <p className='ausbildung_cards__education' > Restaurantfachmann (m/w/d)</p>
            <p className='ausbildung_cards__education' > Tourismuskaufmann (m/w/d)</p>
            <p className='ausbildung_cards__education' > Servicekraft (m/w/d)</p>
            <p className='ausbildung_cards__education' > Bäcker (m/w/d)</p>

            <p className='ausbildung_cards__education_header'>  Ausbildung in den Bereichen IT und EDV:</p>            
            <p className='ausbildung_cards__education' > Fachinformatiker (m/w/d)</p>
            <p className='ausbildung_cards__education' > Informatikkaufmann (m/w/d)</p>
            <p className='ausbildung_cards__education' > Systemkaufmann (m/w/d)</p>
            <p className='ausbildung_cards__education' > IT-Systemelektroniker (m/w/d)</p>

            <p className='ausbildung_cards__education_header'>  Ausbildung im Bereich Lebensmittel:</p>            
            <p className='ausbildung_cards__education' > Fachkraft für Lebensmitteltechnik (m/w/d)</p>
            <p className='ausbildung_cards__education' > Landwirt (m/w/d)</p>
            <p className='ausbildung_cards__education' > Brauer und Mälzer (m/w/d)</p>
            <p className='ausbildung_cards__education' > Fleischer (m/w/d)</p>
            
            <p className='ausbildung_cards__education_header'>  Ausbildung in den Bereichen Logistik und Verkehr:</p>            
            <p className='ausbildung_cards__education' > Binnenschiffer (m/w/d)</p>
            <p className='ausbildung_cards__education' > Eisenbahner (m/w/d)</p>
            <p className='ausbildung_cards__education' > Fachkraft für Kurier-, Express- und Postdienstleistung (m/w/d)</p>

            <p className='ausbildung_cards__education_header'>  Ausbildung im Bereich Metall:</p>            
            <p className='ausbildung_cards__education' > Metallbauer (m/w/d)</p>
            <p className='ausbildung_cards__education' > Rohrleitungsbauer (m/w/d)</p>
            <p className='ausbildung_cards__education' > Werkstoffprüfer (m/w/d)</p>

            


           
      </ol>
    {/* </div> */}
        </li>


         

 </ul>
        </div>
      </div>
    </div>
  );
}

export default AusbildungCard;