import React from 'react';
import '../../App.css';
import './Praktikum.css';
import Footer from '../Footer';
import PraktikumCard from '../PraktikumCard';


function Praktikum() {
    return( 
        
<div>
    <div>
      <div className='Praktikum-container'>
    </div>  
            <div className='Praktikum-btns' ></div>
        </div>
        <PraktikumCard/>
        <Footer />
        </div>
    );


}


export default Praktikum;
