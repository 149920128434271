import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './ServiceWorker';
import 'bootstrap/dist/css/bootstrap.min.css';
import './i18next';


ReactDOM.render(
  <React.StrictMode>


      <Suspense fallback={<h1>Loading profile...</h1>}>
    <App />
      </Suspense>


  </React.StrictMode>,
  document.getElementById('root')
);

serviceWorker.unregister();
