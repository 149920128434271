import React from 'react'
import './CardHome.css'
import { Link } from 'react-router-dom';
import {useTranslation} from "react-i18next";


const CardHome = () =>
{

    const {t} = useTranslation();

    // function handleClick(lang){
    //     i18n.changeLanguage(lang)
    // }


    return(
        <>


            <div className="container_card_home">

            <div className='box_bewerber_unternehmen'>

                <div className="font_home_title">
                    {t('CardStartPage.1')}
                </div>

                <div className="font_home_unternehmen">
                    {t('CardStartPage.3')}
                </div>
                <div className="bottom_home">
                    <div className="bottom_home_style">
                        <Link className='link_home' to="unternehmen">  {t('CardStartPage.5')} </Link>

                </div>
                </div>
                <img
                    className='cards_unternehmen_img'
                    alt='Bild'
                    src={"images/Unternehmen.png"}
                />

            </div>



            <div className='box_bewerber_unternehmen'>
                <div className="font_home_title">
                    {t('CardStartPage.2')}
                </div>
                <div className="font_home_bewerber">
                    {t('CardStartPage.4')}
                </div>
                <div className="bottom_home">
                    <div className="bottom_home_style">
                        <Link className='link_home' to="bewerber">  {t('CardStartPage.5')} </Link>

                    </div>
                </div>
                <img
                    className='cards_bewerber_img'
                    alt='Bild'
                    src={"images/Bewerber.png"}
                />

            </div>


            </div>
        </>
    )
}

export default CardHome;