import React from 'react';
import './FSJCard.css';



function FSJCard() {
  return (
    <div className='fsj_cards'>
      <div className='fsj_cards__container'>
        <div className='fsj__wrapper'>
          <ul className='fsj_cards__items'>

          <li className='fsj_cards__item__link'>
          <div className='fsj_cards__item__info'>
          
        <h1 className='fsj_cards__header__text'> Freiwilliges Soziales Jahr </h1>
        <h1 className='fsj_cards__second__text'> Ein Freiwilliges Soziales Jahr oder ein Bundesfreiwilligendienst ermöglicht dir in Deutschland einen sozialen Beruf auszuüben und somit etwas dem Allgemeinwohl beizutragen. Die Erfahrungen und Kenntnisse die du dort sammelst, helfen dir bei der Orientierung für deinen späteren Berufseinstieg. </h1>

        <ol className='fsj_cards__third__text'> 
        <p className='fsj_cards_textHeader'> Bedingungen um ein FSJ in Deutschland machen zu können:</p>
            <p className='fsj_cards_text'> &#8226; Vollendung der gesetzlichen Schulpflicht</p>
            <p className='fsj_cards_text'> &#8226; Alter: 18-26 Jahre </p>
            <p className='fsj_cards_text'> &#8226; zertifizierte Deutschkenntnisse  d.h. mindestens auf A2-Niveau </p>

            <p className='fsj_cards_textHeader'> Dauer des Freiwilliges Sozialen Jahres:</p>
            <p className='fsj_cards_text'> &#8226; 12 Monate, in Ausnahmefällen auch bis zu 18 Monaten möglich</p>
            <p className='fsj_cards_text'> &#8226; Beginn im August/September oder Januar/Februar </p>
           
            <p className='ferienbeschaeftigung_cards_textHeader'> Vergütung/Leistungen und Unterkunft im Freiwilliges Sozialen Jahres:</p>
            <p className='fsj_cards_text'> &#8226; Taschengeld zwischen 150-350€ im Monat</p>
            <p className='fsj_cards_text'> &#8226; In der Regel freie Unterkunft und Verpflegung durch die Einrichtung </p>
            <p className='fsj_cards_text'> &#8226; 31 Seminartage </p>
            <p className='fsj_cards_text'> &#8226; 26 Tage Urlaub </p>
            <p className='fsj_cards_text'> &#8226; Gesetzliche Sozialversicherung </p>

            <p className='fsj_cards_textHeader'> Was benötigt man für die Bewerbung?</p>
            <p className='fsj_cards_text'> &#8226; Schulabschlusszeugnist</p>
            <p className='fsj_cards_text'> &#8226; Sprachnachweis</p>
            <p className='fsj_cards_text'> &#8226; Motivationsschreiben </p>
            <p className='fsj_cards_text'> &#8226; Von Vorteil: Empfehlungsschreiben eines Lehrers </p>

            <p className='fsj_cards_textHeader'> Wer begleitet dich während der Zeit im FSJ?</p>
            <p className='fsj_cards_text'> &#8226; Fachliche und persönliche Begleitung durch Mitarbeiter der Einrichtung</p>
            <p className='fsj_cards_text'> &#8226; Bist du das erste Mal in Deutschland, nimmst du an Seminaren für internationale Freiwillige teil </p>
          
            <p className='fsj_cards_textHeader'> Wo kann ich ein FSJ machen?</p>
            <p className='fsj_cards__education' > FSJ im Gesundheitsbereich:</p>
            <p className='fsj_cards__education' > Alten- und Pflegeheim</p>
            <p className='fsj_cards__education' > Krankenhaus</p>
            <p className='fsj_cards__education' > Einrichtung für Menschen mit einer Behinderung (z.B. Behindertenwerkstatt),</p>
            <p className='fsj_cards__education' > Ambulanter Sozial- oder Pflegedienst</p>
            <p className='fsj_cards__education' > Sanitäts- und Rettungsdienst</p>
            <p className='fsj_cards__education' > Kindergarten oder Kindertagesstätte

            <p className='fsj_cards__education_header' > FSJ in Kultur und Freizeit:</p>
            <p className='fsj_cards__education'> Musikschulen</p>
            <p className='fsj_cards__education' > Theatern</p>
            <p className='fsj_cards__education' > Jugendkulturzentren</p>
            <p className='fsj_cards__education' > Jugendherbergen</p>
            <p className='fsj_cards__education' > Jugendfeuerwehr</p>

            <p className='fsj_cards__education_header' > FSJ in Sport und Bildung:</p>
            <p className='fsj_cards__education' > Sportverein</p>
            <p className='fsj_cards__education' > Sportverband</p>
            <p className='fsj_cards__education' > Kulturverein</p>
            <p className='fsj_cards__education' > Jugendclub</p>
            <p className='fsj_cards__education' > Förderschule</p>
            <p className='fsj_cards__education' > Ganztagsschule</p>

            <p className='fsj_cards__education_header' > FSJ in Sport und Bildung:</p>
            <p className='fsj_cards__education' > Denkmalpflege</p>
            <p className='fsj_cards__education' > Kirchengemeinde</p>
            <p className='fsj_cards__education' > Gedenkstätte</p>
            <p className='fsj_cards__education' > Museum</p>

            <p className='fsj_cards__education_header' > FSJ in der Politik:</p>            
            <p className='fsj_cards__education' > Bürgerbüros</p>
            <p className='fsj_cards__education' > Ämtern</p>
            <p className='fsj_cards__education' > kirchlichen Verbänden</p>
            <p className='fsj_cards__education' > Menschenrechtsorganisationen</p>

</p>

           
      </ol>
    </div>
        </li>


         

 </ul>
        </div>
      </div>
    </div>
  );
}

export default FSJCard;