import React from 'react';
import "./CardPersonal.css"

function CardPersonal(props) {
    return (
        <>

            <ul className='cards_personal_items'>

                <div className='cards_personal_item_link' to={props.path}>
                    <figure className='cards_personal_item_pic-wrap'>
                        <img
                            className='cards_personal_item_img'
                            alt='Bild'
                            src={props.src}
                        />
                    </figure>
                    <div className='cards_personal_item_text'>
                        <h5 className=''>{props.text}</h5>
                        <h7 className='cards_personal_text_margin'>{props.text_1}</h7>
                    </div>
                </div>
            </ul>
        </>
    );
}

export default CardPersonal;