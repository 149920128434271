import React from 'react'
import './Impressum.css'
import Footer from '../Footer';

function impressum(){

    return(
        <div>
        <div className='impressum_position'>
            <ul>
             <h1>Impressum</h1>

            <div>Email: service@internationaljobplacement.com</div>
            <p> Lichtenberger Straße 34</p> 
            <p>10179 Berlin</p>
            <p>Deutschland</p>
            
            <div>Handelsregisternummer: HRB 207656 B </div>
            </ul>
            </div>
            <div className='footer_impressum'>
            <Footer />
            </div>



        </div>

    )
    
}

export default impressum