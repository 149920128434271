import React from 'react';
import {useEffect} from "react";
import "./UnternehmenP.scss";
import Footer from '../Footer';
import Aos from "aos"
import "aos/dist/aos.css"
import UnternehmenspartnerCardItem from "../UnternehmenspartnerCardItem";
import Accordion from "../Accordion";
import Plx from "react-plx";
import {useTranslation} from "react-i18next";

import ContactForm from "../ContactForm";

const exampleParallaxData = [
    {
        start: 0,
        end: 1500,
        properties: [
            {
                startValue: 0,
                endValue: -400,
                property: "translateY"
            }
        ]
    }]

const UnternehmenP = () => {



    useEffect(() => {
        Aos.init({duration: 2000});
    }, []);

    let scrollRef = 0;

    window.addEventListener('scroll', function () {
        // increase value up to 10, then refresh AOS
        scrollRef <= 1 ? scrollRef++ : Aos.refresh();
    });

    const {t} = useTranslation();

    return (
        <div>
            <div className="unternehmen-container"></div>
            <Plx parallaxData={exampleParallaxData}>
                <section>
                    <UnternehmenspartnerCardItem
                        text1=
                            {t('UnternehmerPage.1')} 
                        text2=
                            {t('UnternehmerPage.2')} 
                        text3=
                            {t('UnternehmerPage.3')} />
                </section>

                <div>
                <div style={{backgroundColor: "white"}}>
                    <h1 style={{marginTop: "150px"}} id="first-fade-in">   {t('UnternehmerPage.4')} </h1>


                    <div className="procedure_containter" style={{marginTop: "10px"}}>
                        <img
                            className='procedure_img'
                            alt='Bild'
                            src={"images/Schritt1.png"}
                        />

                        <div className="procedure" data-aos="fade-left" data-aos-anchor="#first-fade-in"
                           id="second-fade-in">
                            <h2>  {t('UnternehmerPage.5')}</h2>
                             {t('UnternehmerPage.6')}
                        </div>

                    </div>
                    <div className="procedure_containter">
                        <p className="procedure" data-aos="fade-right" data-aos-anchor="#second-fade-in"
                           id="third-fade-in">
                            <h2> {t('UnternehmerPage.7')}</h2>
                            {t('UnternehmerPage.8')}
                        </p>

                        <img
                            className='procedure_img'
                            alt='Bild'
                            src={"images/Schritt2.png"}
                        />

                    </div>

                    <div className="procedure_containter">

                        <img
                            style={{marginBottom: "80px"}}
                            className='procedure_img'
                            alt='Bild'
                            src={"images/Schritt3.png"}
                        />

                        <p className="procedure" data-aos="fade-left" data-aos-anchor="#third-fade-in"
                           id="forth-fade-in">
                            <h2> {t('UnternehmerPage.9')}</h2>
                           {t('UnternehmerPage.10')}
                        </p>

                    </div>

                </div>
                    <div className="procedure_containter">

                        <p className="procedure" data-aos="fade-right" data-aos-anchor="#forth-fade-in">
                            <h2> {t('UnternehmerPage.11')}</h2>
                            {t('UnternehmerPage.12')}
                        </p>


                        <img style={{marginBottom: "100px"}}
                             className='procedure_img'
                             alt='Bild'
                             src={"images/Schritt4.png"}
                        />

                    </div>
                </div>

                <div className="accordion_backgroundcolor">
                    <section className="accordion_containter">

                        <div className='one' style={{color: 'floralwhite'}}>
                            <h2> {t('UnternehmerPage.13')} </h2>
                            <p> {t('UnternehmerPage.14')}.</p>
                        </div>

                        <div style={{position: 'absolute', marginTop: "-550px"}} id="Ferienbeschäftigung"></div>
                        <div style={{position: 'absolute', marginTop: "-550px"}} id="Praktikum"></div>
                        <div style={{position: 'absolute', marginTop: "-550px"}} id="FSJ"></div>
                        <div style={{position: 'absolute', marginTop: "-550px"}} id="Ausbildung"></div>

                        <div className='two' >
                            <Accordion/>

                        </div>

                    </section>

                </div>

                <div className="contact_from">
                    <ContactForm/>
                </div>

            </Plx>

            <Footer/>


        </div>
    );
}

export default UnternehmenP