import React from 'react';
import '../../App.css';
import HeroSection from '../HeroSection';
import Footer from '../Footer';
import Numbers from '../Numbers';
import CardHome from '../CardHome';
// import Partner from '../Partner';


function Home() {

/*    const {t, i18n} = useTranslation();*/

/*    function handleClick(lang){
        i18n.changeLanguage(lang)
    }*/


    return(
        <>

            <HeroSection />
            <CardHome/>
            <Numbers />
            <Footer />
        </>
    )
}

export default Home;