import React from 'react';
//import Navbar from './components/Navbar';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import './App.css';
import Home from './components/pages/Home';
import Services from './components/pages/Services';
import Bewerber from './components/pages/Bewerber';
import SignUp from './components/pages/SignUp';
import NavbarBootstrap from './components/NavbarBootstrap';
import Impressum from './components/pages/Impressum';
import Datenschutz from './components/pages/Datenschutz';
import Ferienbeschaeftigung from './components/pages/Ferienbeschaeftigung'
import FSJ from './components/pages/FSJ'
import Ausbildung from './components/pages/Ausbildung'
import Praktikum from './components/pages/Praktikum'
import Kontakt from './components/pages/Kontakt'
import UeberUns from './components/pages/UeberUns'
import UnternehmenP from './components/pages/UnternehmenP'
import ScrollToTop from './components/ScrollToTop'

function App() {
  return(
    <>
      <Router>

        <NavbarBootstrap/>
        <ScrollToTop/>
        <Switch>
          <Route path='/services' exact component={Services}/>
          <Route path='/' exact component={Home}/>

          <Route path='/bewerber' exact component={Bewerber}/>

          {/*<Route path='/unternehmen' exact component={Unternehmen}/>*/}
          <Route path='/sign-up' exact component={SignUp}/>
          <Route path='/impressum' exact component={Impressum}/>
          <Route path='/datenschutz' exact component={Datenschutz}/>
          <Route path='/ferienbeschäftigung' exact component={Ferienbeschaeftigung}/>
          <Route path='/freiwilligessozialesjahr' exact component={FSJ}/>
          <Route path='/ausbildung' exact component={Ausbildung}/>
          <Route path='/praktikum' exact component={Praktikum} />
          <Route path='/kontakt' exact component={Kontakt}/>
          <Route path='/ueberuns' exact component={UeberUns}/>
          <Route path='/unternehmen' exact component={UnternehmenP}/>
        </Switch>
      </Router>
       
    </>  
  );
}

export default App;
