import React from 'react';
// import './BewerberCards.css';
import './CardItem.css'
import {useTranslation} from "react-i18next";

function BewerberCardItems(props) {

    const {t} = useTranslation();

/*    function handleClick(lang){
        i18n.changeLanguage(lang)
    }*/

  return (
      <div className='bewerber_cards'>
        <div className='bewerber_cards_container'> 
        <div className="bewerber_cards__item__link">
            <h1 className='bewerber_cards__header__text'>{props.textHeader} </h1>
            <h1 className='bewerber_cards__second__text'>{props.textUeber}</h1>
            <h5 className='bewerber_cards__third__text'>{props.text}</h5>
            <h5 className='bewerber_cards__third__text'>{props.text2}</h5>
            <h5 className='bewerber_cards__third__text_b'>{props.text3}</h5>

            <a className='bewerber_cards__link__text '
                href='https://docs.google.com/forms/d/1O87-xbJhBsx2ggvKiSg37O5XIQd-CiZ9DOuIJhsBkBI/viewform?fbclid=PAAaY9TX--1ldxlMyBkDWFNW9W7nL09WuLsMnYN_pLbvc_xjtZ_b0XIRZ0llo_aem_ATwEB8bGNZ0Ls66nqEcvwgz5w2aA44YKQdV5SlOemyLZzP9CRqiQLPKp3RMxtLdtaqo&edit_requested=true'
              >
                {t('InfoStudents.6')}
              </a>
          </div>
</div>
</div>

  );
}

export default BewerberCardItems;